.room {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

}

.imageContainer {
    width: 100%;
    /*height: 180px;*/
    height: 100%;
    overflow: hidden;
    border-radius: 10px 10px 0 0;
}

.room img {
    max-width: inherit;
    max-height: inherit;
    height: inherit;
    width: inherit;
    object-fit: cover;
}

.roomInfo {
    background-color: #055680;
    padding: 8px 10px;
    border-radius: 0 0 10px 10px;
}

.roomInfo span {
    display: block;
    font-size: 0.8rem;
    width: fit-content;
    font-weight: normal;
}

.roomInfo span:first-child {
    font-size: 1rem;
    font-weight: bolder;
}

.room {
    animation: card-default 0.5s forwards;
}

.room:hover {
    animation: card-hover 0.5s forwards;
    cursor: pointer;
}

.room:hover .roomInfo {
    background-color: #00ccff;
}

@keyframes card-hover {
    0% {transform: translateY(0);}
    100% {transform: translateY(-10px);}
}

@keyframes card-default {
    0% {transform: translateY(-10px);}
    100% {transform: translateY(0);}
}
