.content {
    max-width: 1000px;
    margin: auto;
    padding: 0 150px;
    padding-top: 180px;
}

@media screen and (max-width: 840px) {
    .content {
        padding: 0 50px;
        padding-top: 150px
    }
}

@media screen and (max-width: 480px) {
    .content {
        padding: 0 25px;
        padding-top: 120px
    }
}
