.container {
    margin-top: 20px;
    display: grid;
    /*grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));*/
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    /*grid-template-rows: repeat(auto-fit, minmax(100px, 1fr));*/
    grid-auto-rows: minmax(20px, 120px);
    gap: 20px;
}

@media screen and (max-width: 480px) {
    .container {
        justify-content: center;
        grid-template-columns: repeat(auto-fit, minmax(220px, 270px));
        grid-auto-rows: minmax(20px, 100px);
        gap: 10px;
    }
}
