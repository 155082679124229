
.container {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: start;
    width: 100%;
}

.icon {

}

.device {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    background: linear-gradient(90deg, rgba(14,94,156,1) 0%, rgba(17,102,126,1) 30%, rgba(17,152,180,1) 100%);
    padding: 10px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.device-off {
    background: #ffffff;
}

.device-off .info span {
    color: black;
}

.device-on {
    background: linear-gradient(90deg, rgba(14,94,156,1) 0%, rgba(17,102,126,1) 30%, rgba(17,152,180,1) 100%);
}

.device:before {
    position: absolute;
    top:0;
    left:0;
    content: '';
    opacity: 10%;
    background-color: #0ca5c8;
    height: 100%;
    width: 100%;
    transition: opacity 500ms ease-out;
}

.device:after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    content: '';
    background-color: #4f1c55;
    border-radius: 5px;
    height: 60px;
    margin: auto;
    width: 4px;
    transition: background-color 500ms ease-out;
}

.info {
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
}

.info span {
    display: block;
    font-size: 0.8rem;
    width: fit-content;
    font-weight: normal;
}

.info span:first-child {
    font-size: 1rem;
    font-weight: bolder;
}

.state {
    font-weight: bolder !important;
    /*color: #0ca5c8 !important;*/
    margin-top: 10px;
}

.device {
    animation: card-default 0.5s forwards;
}

.device:hover {
    animation: card-hover 0.5s forwards;
    cursor: pointer;
}

.device:hover:before {
    opacity: 35%;
}

.device:hover:after {
    background-color: #bb4fb9;
}

@keyframes card-hover {
    0% {transform: translateY(0);}
    100% {transform: translateY(-10px);}
}

@keyframes card-default {
    0% {transform: translateY(-10px);}
    100% {transform: translateY(0);}
}
