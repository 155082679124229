.rooms {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    /*grid-template-rows: repeat(auto-fit, minmax(100px, 1fr));*/
    grid-auto-rows: minmax(100px, 200px);
    gap: 20px;
}

@media screen and (max-width: 480px) {
    .rooms {
        grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
        grid-auto-rows: minmax(100px, 150px);
        gap: 10px;
    }
}
