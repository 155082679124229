.greetings span {
    font-size: 1.5rem;
    color: whitesmoke;
}

.greetings h3 {
    font-size: 2rem;
    color: whitesmoke;
}


.content span {
    font-size: 1.2rem;
    color: white;
    font-weight: bolder;
}

.content {
    margin-top: 30px;
}

.content:nth-child(1) {
    margin-top: 30px;
}
